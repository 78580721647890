
import axios from 'axios';

export interface IWebhook {
  id: string,
  url: string,
  name: string,
  body: string,
  activeSearch: boolean,
  labelSearch?: string,
  urlSearch?: string,
  idCompany: number;
  needCustomerEmail: boolean,
  searchCustomerUserUrl: string
}


export interface IWebhookSend {
  url: string,
  name: string,
  body: string,
  id_company: string,
  activeWebhookSearch: boolean,
  labelWebhookSearch?: string,
  webhookSearch?: string,
  needCustomerEmail: boolean,
  searchCustomerUserUrl: string
}

interface ISelectWebhook {
  key: string,
  value: string
}

export default {
  async listWebhooks(ids: string[]) {

    const baseUrl: string = process.env.REACT_APP_URL_APP_BOT_API + 'webhook' || "";
    const tokenJWT = localStorage.getItem("auth");

    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${tokenJWT}`,
    }

    const url = baseUrl + '/list.php';

    return await axios.post<IWebhook[]>(url,
      { id_company: ids },
      {
        headers
      }).then(request => {
        return request.data;
      }).catch(err => {
        throw err;
      });
  },
  async createWebhook(webhook: IWebhookSend) {

    const baseUrl: string = process.env.REACT_APP_URL_APP_BOT_API + 'webhook' || "";
    const tokenJWT = localStorage.getItem("auth");

    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${tokenJWT}`,
    }

    const url = baseUrl + '/create.php';

    return await axios.post<IWebhook[]>(url,
      { 
        id_company: webhook.id_company, 
        name: webhook.name, 
        url: webhook.url, 
        body: webhook.body, 
        active_search: webhook.activeWebhookSearch, 
        label_search: webhook.labelWebhookSearch, 
        url_search: webhook.webhookSearch, 
        need_customer_email: webhook.needCustomerEmail, 
        search_customer_user_url: webhook.searchCustomerUserUrl
      },
      {
        headers
      }).then(request => {
        return request.data;

      }).catch(err => {

        throw err;
      });
  },
  async getWebhook(webhookID: string): Promise<boolean | IWebhook> {

    if(!webhookID || webhookID=="0") return false;

    const baseUrl: string = process.env.REACT_APP_URL_APP_BOT_API + 'webhook' || "";
    const tokenJWT = localStorage.getItem("auth");

    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${tokenJWT}`,
    }

    const url = baseUrl + '/get.php';

    return await axios.post<IWebhook>(url,
      { id: webhookID },
      {
        headers
      }).then(request => {
        return request.data;

      }).catch(err => {

        throw err;
      });
  },
  async updateWebhook(webhook: IWebhook) {

    const baseUrl: string = process.env.REACT_APP_URL_APP_BOT_API + 'webhook' || "";
    const tokenJWT = localStorage.getItem("auth");

    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${tokenJWT}`,
    }

    const url = baseUrl + '/update.php';

    return await axios.post<IWebhook>(url,
      { 
        id: webhook.id, 
        id_company: webhook.idCompany, 
        name: webhook.name, 
        url: webhook.url, 
        body: webhook.body, 
        active_search: webhook.activeSearch, 
        label_search: webhook.labelSearch, 
        url_search: webhook.urlSearch, 
        need_customer_email: webhook.needCustomerEmail, 
        search_customer_user_url: webhook.searchCustomerUserUrl
      },
      {
        headers
      }).then(request => {
        return request.data;

      }).catch(err => {
        return false;
      });
  },
  async deleteWebhook(id: string) {

    const baseUrl: string = process.env.REACT_APP_URL_APP_BOT_API + 'webhook' || "";
    const tokenJWT = localStorage.getItem("auth");

    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${tokenJWT}`,
    }

    const url = baseUrl + '/delete.php';

    return await axios.post<IWebhook>(url,
      { id: id },
      {
        headers
      }).then(request => {
        return request.data;

      }).catch(err => {

        throw err;
      });

  },
  async getListTickets(phone: string, id_webhook: string) {

    const baseUrl: string = process.env.REACT_APP_URL_APP_BOT_API + 'webhook' || "";
    const tokenJWT = localStorage.getItem("auth");

    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${tokenJWT}`,
    }

    const url = baseUrl + '/listTickets.php';

    return await axios.post<ISelectWebhook[]>(url,
      { phone, id_webhook },
      {
        headers
      }).then(request => {
        return request.data;

      }).catch(err => {

        throw err;
      });
  },

  async getCustomerUserDataByPhone(urlOTRS: string, phone: string) {

    const baseUrl: string = process.env.REACT_APP_URL_APP_BOT_API + 'webhook' || "";
    const tokenJWT = localStorage.getItem("auth");

    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${tokenJWT}`,
    }

    const url = baseUrl + '/getCustomerUserDataByPhone.php';

    return await axios.post<any>(url,
      { phone, urlOTRS },
      {
        headers
      }).then(request => {
        return request.data;

      }).catch(err => {

        throw err;
      });
  },

}