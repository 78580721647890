
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as S from './styles';

import CodeEditor from '@uiw/react-textarea-code-editor';

import { Check } from '@mui/icons-material';
import { Button, Checkbox, FormControl, FormControlLabel, InputLabel, MenuItem, OutlinedInput, Select, SelectChangeEvent, TextField, ToggleButton, ToggleButtonGroup } from '@mui/material';
import React from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { useMainContext } from "../../../../Contexts/Contexts";
import { useAppSelector } from "../../../../hooks/redux-hooks";
import departmentService, { IDepartmentEdit } from "../../../../service/departmentService";
import userService, { IRelationCompany } from "../../../../service/userService";
import webhookService, { IWebhook, IWebhookSend } from '../../../../service/webhookService';
import { InputDiv, StyledDialog, TitleContainer } from '../../Departament/EditDepartament/styles';
import { ErrorSpan } from '../../Departament/NewDepartament/styles';

interface FormSend {
    name: string;
    url: string;
    labelSearch?: string;
    activeWebhookSearch?: Boolean;
    urlSearch?: string;
    labelWebhookSearch?: string;
    webhookSearch?: string;
    idCompany?: number;
    needCustomerEmail?: Boolean;
    searchCustomerUserUrl?: string;
}

const EditWebhook: React.FC = () => {
    const Contexts = useMainContext();

    const { register, handleSubmit, setValue, formState: { errors } } = useForm<FormSend>();
    const [companySelected, setCompanySelected] = useState<string>("");
    const [code, setCode] = useState<string>("{}");
    const [companies, setCompanies] = useState<IRelationCompany[]>([]);
    const [departments, setDepartments] = useState<{ id: number, value: string }[]>([]);
    const [webhookSearch, setWebhookSearch] = useState<boolean>(true);
    const [needCustomerEmail, setNeedCustomerEmail] = useState<boolean>(false);
    const [alignment, setAlignment] = React.useState<string>("");
    const { id } = useParams();

    const nav = useNavigate();

    const [optionsCompany, setOptionsCompany] = useState<Array<{ value: string; label: string; }>>();
    const [selectedOptionsCompany, setSelectedOptionsCompany] = useState<Array<{ value: string; label: string; }>>();

    const companys = useAppSelector(state => state.chat.companys);

    const handleToggleChange = (
        event: React.MouseEvent<HTMLElement>,
        newAlignment: string,
    ) => {
        setAlignment(newAlignment);
    };

    const searchDepartmentsByCompany = async (id: number) => {
        departmentService.getDepartmentByCompanyID(id).then(
            result => {

                if (!result) return;

                const departmentsOptionsFormatted = result.map((dep: { name: string; id: string; }) => {
                    return { value: dep.name, id: parseInt(dep.id) }
                });

                setDepartments(departmentsOptionsFormatted);

            }
        );
    }
    useEffect(() => {
        userService.getRelationUserCompany().then(response => {
            const companies = response;
            const allCompanies = companies.map(company => company);
            setCompanies(allCompanies)


            if (response.length > 0) {

                const companySelect = parseInt(response[0].id_company);


                //setCompanySelected(companySelect);

                searchDepartmentsByCompany(companySelect);
            }
        });
    }, []);

    useEffect(() => {
        webhookService.getWebhook(Contexts.id! || "0")
            .then(result => {
                if (typeof result !== "boolean") {
                    const idCompany = result.idCompany;
                    setCompanySelected(idCompany.toString());
                }
            })
    }, [optionsCompany])

    useEffect(() => {
        if (id === undefined || id === 'NEW') return;
        webhookService.getWebhook(id).then(res => {
            if (typeof res !== "boolean") {
                setCode(res.body);
                setValue("name", res.name);
                setValue("url", res.url);
                setValue("labelWebhookSearch", res.labelSearch);
                res.activeSearch ? setWebhookSearch(true) : setWebhookSearch(false);
                res.needCustomerEmail ? setNeedCustomerEmail(true) : setNeedCustomerEmail(false);
                setValue("activeWebhookSearch", res.activeSearch);
                setValue("webhookSearch", res.urlSearch);
                setValue("needCustomerEmail", res.needCustomerEmail);
                setValue("searchCustomerUserUrl", res.searchCustomerUserUrl);
            }
        });
    }, [id, setValue]);

    useEffect(() => {

        const newCompanys: Array<{ value: string; label: string; }> = [];

        companys?.forEach(e => {
            let newOption = {
                value: String(e.id),
                label: e.name,
            }
            newCompanys.push(newOption);
        });

        let result = [];

        const selectedCompanys_local = localStorage.getItem("selected-companys");

        if (selectedCompanys_local) {
            result = JSON.parse(selectedCompanys_local);
            setSelectedOptionsCompany(result);
        }

        setOptionsCompany(newCompanys)


    }, [companys]);



    useEffect(() => {

        if (webhookSearch && id !== undefined) {
            webhookService.getWebhook(id).then(res => {
                if (typeof res !== "boolean") {
                    setValue("labelWebhookSearch", res.labelSearch);
                    setValue("activeWebhookSearch", res.activeSearch);
                    setValue("webhookSearch", res.urlSearch);
                }
            });
        }

    }, [webhookSearch, id, setValue]);

    useEffect(() => {
        setWebhookSearch(alignment == "Enable");
    }, [alignment]);


    const handleChangeCompanySelect = (event: SelectChangeEvent<string>) => {
        setCompanySelected(event.target.value);
        //setSelectedOptionCompany(event.target.value);
    };

    const sendForm = (data: FormSend) => {
        console.log('Sending...')
        if (selectedOptionsCompany !== undefined) {

            if (id === 'NEW') {
                const formSend: IWebhookSend = {
                    name: data.name,
                    url: data.url,
                    body: code,
                    id_company: selectedOptionsCompany[0].value,
                    activeWebhookSearch: webhookSearch,
                    labelWebhookSearch: data.labelWebhookSearch,
                    webhookSearch: data.webhookSearch,
                    needCustomerEmail: needCustomerEmail,
                    searchCustomerUserUrl: data.searchCustomerUserUrl ?? "",
                }

                webhookService.createWebhook(formSend).then(
                    res => {
                        nav(`/admin/webhook`);
                        Contexts.setPopupModalOpen(true);
                        Contexts.setPopupModalText("Webhook atualizado!");
                        Contexts.setEditWebhookOpen(false);
                    }
                ).catch(() => {
                    Contexts.setPopupModalErrorOpen(true);
                    Contexts.setPopupModalErrorText("Oops, algo deu errado!");
                });
            } else {
                if (id) {
                    const formSend: IWebhook = {
                        id: id,
                        name: data.name,
                        url: data.url,
                        body: code,
                        activeSearch: webhookSearch,
                        labelSearch: data.labelWebhookSearch,
                        urlSearch: data.webhookSearch,
                        idCompany: parseInt(companySelected!),
                        needCustomerEmail: needCustomerEmail,
                        searchCustomerUserUrl: data.searchCustomerUserUrl ?? "",
                    }

                    webhookService.updateWebhook(formSend).then(
                        res => {
                            if (companySelected == null) {
                                Contexts.setPopupModalErrorOpen(true);
                                Contexts.setPopupModalErrorText("Por favor, selecione uma empresa!");
                                return;
                            } else {
                                Contexts.setPopupModalOpen(true);
                                Contexts.setPopupModalText("Webhook atualizado!");
                                Contexts.setEditWebhookOpen(false);
                                nav(`/admin/webhook`);
                            }
                        },
                        error => { throw error }
                    );
                } else {
                    Contexts.setPopupModalErrorOpen(true);
                    Contexts.setPopupModalErrorText("Oops, algo deu errado!");
                }

            }

        } else {
            Contexts.setPopupModalErrorOpen(true);
            Contexts.setPopupModalErrorText("Oops, parece que algo deu errado!");
        }
    }

    useEffect(() => {

        const newCompanys: Array<{ value: string; label: string; }> = [];

        companies.forEach(e => {
            let newOption = {
                value: String(e.id_company),
                label: e.name_company,
            }
            newCompanys.push(newOption);
        })

        // setSelectedOptionCompany(newCompanys);
        setOptionsCompany(newCompanys)


    }, [companies]);

    const theme = localStorage.getItem('theme');

    const handleClose = () => {
        Contexts.setEditWebhookOpen(false)
        nav("/admin/webhook")
    };

    return (
        <StyledDialog open={Contexts.editWebhookOpen} onClose={handleClose} sx={{ backdropFilter: 'none' }} data-theme={theme ? theme : 'light'}>

            <S.WebhookFormWrapper data-theme={theme ? theme : 'light'} onSubmit={handleSubmit(sendForm, (e) => console.log(e))}>
                <TitleContainer>
                    {id === 'NEW' ? 'Criar webhook' : 'Editar webhook'}
                </TitleContainer>

                <S.WebhookForm>
                    {/* <S.WebhookInputWrapper>
                        <FormTextInput
                            label="Nome"
                            placeholder="Nome do Webhook"
                            register={register}
                            errors={errors.name}
                            name='name'
                        />
                    </S.WebhookInputWrapper> */}

                    <S.WebhookInputWrapper style={{ display: "flex", marginTop: "0px" }}>

                        <TextField sx={{ width: "100%" }}
                            key="nameEditInput"
                            autoFocus
                            error={errors.name ? true : false}
                            margin="dense"
                            id="name"
                            label="Nome"
                            placeholder="Nome do webhook"
                            type="text"
                            {...register("name", { required: true, maxLength: 150, minLength: 3 })}
                            variant="filled" InputLabelProps={{ shrink: true, style: { color: 'var(--text-modal)' } }}
                        />
                        {errors.name && <ErrorSpan>Este campo precisa de pelo menos três caracteres!</ErrorSpan>}
                        <FormControl required sx={{ m: 1, marginRight: "0px", minWidth: 130, width: '70%' }}>
                            <InputLabel id="demo-simple-select-autowidth-label" sx={{ fontSize: "16px", color: 'var(--text-modal)' }} >Selecione a empresa</InputLabel>
                            <Select
                                key="selectEditInput"
                                labelId="demo-simple-select-label"
                                id="demo-simple-select-autowidth"
                                error={errors.idCompany ? true : false}
                                {...register("idCompany", { required: true })}
                                value={companySelected}
                                onChange={handleChangeCompanySelect}
                                autoWidth
                                inputProps={{ shrink: true, style: { color: 'var(--text-modal)' } }}
                                input={<OutlinedInput id="demo-simple-select-label" label="Selecione a empresa" />}
                                sx={{
                                    backgroundColor: 'var(--input-bg-color)', width: '100%', color: "var(--text-modal)", '.MuiOutlinedInput-notchedOutline': {
                                        borderColor: 'rgba(228, 219, 233, 0.25)',
                                    },
                                }}
                            >
                                {optionsCompany ? optionsCompany.map(e => (
                                    <MenuItem value={e.value.toString()} key={e.value}> {e.label} </MenuItem>
                                )) : <MenuItem> <p>Nenhuma empresa cadastrada!</p> </MenuItem>}

                            </Select>
                            {errors.idCompany && <ErrorSpan>Este campo precisa estar selecionado</ErrorSpan>}
                        </FormControl>
                    </S.WebhookInputWrapper>
                    <InputDiv>
                        <TextField
                            key="urlEditInput"
                            error={!!errors.url}
                            margin="dense"
                            id="url"
                            label="url"
                            placeholder="URL do webhook"
                            type="text"
                            {...register("url", { required: true, maxLength: 150, minLength: 3 })}
                            fullWidth
                            variant="filled"
                            InputLabelProps={{ shrink: true, style: { color: 'var(--text-modal)' } }}
                        />
                    </InputDiv>
                    {errors.url && <ErrorSpan>Este campo precisa de pelo menos três caracteres!</ErrorSpan>}
                    <S.WebhookInputWrapper>
                        <label>Mapeamento JSON</label>
                        <CodeEditor
                            value={code}
                            name="code"
                            language="json"
                            placeholder="Please enter JSON code."
                            onChange={(evn) => setCode(evn.target.value)}
                            padding={15}
                            style={{
                                backgroundColor: 'var(--code-editor-bg)',
                                fontSize: 18,
                                width: '100%',
                                borderRadius: "4px",
                                fontFamily: 'ui-monospace,SFMono-Regular,SF Mono,Consolas,Liberation Mono,Menlo,monospace',
                            }}
                        />

                    </S.WebhookInputWrapper>

                    <S.WebhookInputWrapper>
                        {/* <FormControlLabel
                        labelPlacement="start"
                        label="Deseja ativar a busca prévia?"
                        control={
                            <Switch checked={webhookSearch} onChange={() => setWebhookSearch(!webhookSearch)} name="activeWebhookSearch" />
                        }
                    /> */}

                        <ToggleButtonGroup
                            color="success"
                            value={alignment}
                            exclusive
                            onChange={handleToggleChange}
                            aria-label="Platform"
                            sx={{ display: "flex", justifyContent: "center" }}
                        >
                            <ToggleButton selected={webhookSearch} defaultChecked={true} value="Enable" sx={{ color: "var(--text-primary)", borderColor: "var(--text-primary)" }}>Ativar busca prévia</ToggleButton>
                            <ToggleButton value="Disable" sx={{ color: "var(--text-primary)", borderColor: "var(--text-primary)" }}>Desativar busca prévia</ToggleButton>
                        </ToggleButtonGroup>
                    </S.WebhookInputWrapper>
                    {webhookSearch ?
                        <S.WrapperWebhookSearch>
                            <h3>Definição de Webhook de busca prévia</h3>
                            <S.WebhookInputWrapper>
                                {/* <FormTextInput
                                label="Label que exibe a opção de retorno da chamada de busca"
                                placeholder="Insira a label que indicará o retorno da busca prévia"
                                register={register}
                                errors={errors.name}
                                required={webhookSearch}
                                name='labelWebhookSearch'
                            /> */}

                                <InputDiv>
                                    <TextField
                                        key="labelWebhookSearchInput"
                                        required={webhookSearch}
                                        error={errors.labelWebhookSearch ? true : false}
                                        margin="dense"
                                        id="labelWebhookSearch"
                                        label="Label da busca"
                                        placeholder="Label da busca"
                                        type="text"
                                        {...register("labelWebhookSearch", { required: false, maxLength: 150, minLength: 3 })}
                                        fullWidth
                                        variant="filled"
                                        InputLabelProps={{ shrink: true, style: { color: 'var(--text-modal)' } }}
                                    />
                                </InputDiv>
                                {errors.labelWebhookSearch && <ErrorSpan>Este campo precisa de pelo menos três caracteres!</ErrorSpan>}
                            </S.WebhookInputWrapper>

                            <S.WebhookInputWrapper>
                                {/* <FormTextInput
                                label="Webhook de busca prévia"
                                placeholder="Insira o webhook que fará uma busca prévia"
                                register={register}
                                errors={errors.name}
                                required={webhookSearch}
                                name='webhookSearch'
                            /> */}

                                <InputDiv>
                                    <TextField
                                        key="webhookSearchInput"
                                        required={webhookSearch}
                                        error={errors.webhookSearch ? true : false}
                                        margin="dense"
                                        id="URL da busca"
                                        label="URL da busca"
                                        placeholder="Sigla da empresa"
                                        type="text"
                                        {...register("webhookSearch", { required: false, maxLength: 150, minLength: 3 })}
                                        fullWidth
                                        variant="filled"
                                        InputLabelProps={{ shrink: true, style: { color: 'var(--text-modal)' } }}
                                    />
                                </InputDiv>
                                {errors.webhookSearch && <ErrorSpan>Este campo precisa de pelo menos três caracteres!</ErrorSpan>}
                            </S.WebhookInputWrapper>

                        </S.WrapperWebhookSearch>
                        : false
                    }
                    {
                        <>
                            <FormControlLabel control={<Checkbox checked={needCustomerEmail} onChange={() => setNeedCustomerEmail(!needCustomerEmail)} />} label="Ativar busca de Usuário" />
                            <InputDiv>
                                <TextField
                                    key="searchCustomerUserUrl"
                                    error={!!errors.searchCustomerUserUrl && !!needCustomerEmail}
                                    margin="dense"
                                    id="searchCustomerUserUrl"
                                    label="URL da pesquisa pelo Usuário"
                                    placeholder="URL da pesquisa pelo Usuário"
                                    type="text"
                                    {...register("searchCustomerUserUrl", { required: !!needCustomerEmail, maxLength: 150, minLength: 3 })}
                                    fullWidth
                                    variant="filled"
                                    disabled={!needCustomerEmail}
                                    InputLabelProps={{ shrink: true, style: { color: 'var(--text-modal)' } }}
                                />
                            </InputDiv>
                            {errors.searchCustomerUserUrl && !!needCustomerEmail && <ErrorSpan>Este campo precisa de pelo menos três caracteres!</ErrorSpan>}
                        </>
                    }
                    <S.WebhookInputWrapper style={{
                        display: "flex",
                        justifyContent: "end"
                    }}>
                        < Button
                            sx={{ right: "0" }}
                            variant="contained"
                            color="success"
                            type='submit'
                            startIcon={<Check />}>
                            {id === 'NEW' ? 'Criar webhook' : 'Editar webhook'}
                        </Button>

                        {/* <button onClick={handleSubmit(sendForm)}>{id === 'NEW' ? 'Criar' : 'Atualizar'}</button> */}
                    </S.WebhookInputWrapper>
                </S.WebhookForm >
            </S.WebhookFormWrapper >
        </StyledDialog>
    )
}

export default EditWebhook;